import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Breakpoint } from '@core/enums';
import { Breakpoints } from '@core/models';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class BreakpointObserverService {
	constructor(private breakpointObserver: BreakpointObserver) {}

	private readonly _breakpoints$ = new ReplaySubject<Breakpoints>(1);
	private readonly _isDesktop$ = new BehaviorSubject<boolean>(false);
	private readonly _isMobile$ = new BehaviorSubject<boolean>(false);

	get breakpoints$(): Observable<Breakpoints> {
		return this._breakpoints$.asObservable();
	}

	get isDesktop$(): Observable<boolean> {
		return this._isDesktop$.asObservable();
	}

	get isMobile$(): Observable<boolean> {
		return this._isMobile$.asObservable();
	}

	private setIsDesktop(isDesktop: boolean): void {
		this._isDesktop$.next(isDesktop);
	}

	private setIsMobile(isMobile: boolean): void {
		this._isMobile$.next(isMobile);
	}

	isMobile(): boolean {
		return this._isMobile$.getValue();
	}

	init(): void {
		this.breakpointObserver
			.observe(Object.values(Breakpoint))
			.pipe(distinctUntilChanged())
			.subscribe((state: BreakpointState) => {
				this._breakpoints$.next(state.breakpoints);

				this.setIsDesktop(state.breakpoints[Breakpoint.LG]);
				this.setIsMobile(state.breakpoints[Breakpoint.XS] && !state.breakpoints[Breakpoint.SM]);
			});
	}
}
